$breakpoint-xs: 450px;
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

@mixin respond($breakpoint) {
  @if ($breakpoint==xs) {
    @media (min-width: 200px) and (max-width: $breakpoint-xs) {
      @content;
    }
  }

  @if ($breakpoint==phone) {
    @media (min-width: 451px) and (max-width: $breakpoint-sm) {
      @content;
    }
  }

  @if ($breakpoint==small) {
    @media (min-width: 641px) and (max-width: $breakpoint-md) {
      @content;
    }
  }

  @if ($breakpoint==medium) {
    @media (min-width: 769px) and (max-width: $breakpoint-lg) {
      @content;
    }
  }

  @if ($breakpoint==large) {
    @media (min-width: 1025px) and (max-width: $breakpoint-xl) {
      @content;
    }
  }

  @if ($breakpoint==full) {
    @media (min-width: 1281px) {
      @content;
    }
  }
}

.colVideo {
  @include respond(xs) {
    min-height: 80vh !important;
  }

  @include respond(phone) {
    min-height: 70vh !important;
  }

  @include respond(small) {
    min-height: 70vh !important;
  }

  @include respond(medium) {
    min-height: 75vh !important;
  }
  @include respond(large) {
    min-height: 90vh !important;
  }
  @include respond(full) {
    min-height: 90vh !important;
  }
}

.colInfoCita {
  @include respond(xs) {
    min-height: 20vh !important;
  }

  @include respond(phone) {
    min-height: 45vh !important;
  }

  @include respond(small) {
    min-height: 45vh !important;
  }

  @include respond(medium) {
    min-height: 45vh !important;
  }
  @include respond(large) {
    min-height: 45vh !important;
  }
  @include respond(full) {
    min-height: 45vh !important;
  }
}

.colFormDiagnostico {
  @include respond(xs) {
    min-height: 50vh !important;
  }

  @include respond(phone) {
    min-height: 90vh !important;
  }

  @include respond(small) {
    min-height: 90vh !important;
  }

  @include respond(medium) {
    min-height: 90vh !important;
  }
  @include respond(large) {
    min-height: 90vh !important;
  }
  @include respond(full) {
    min-height: 90vh !important;
  }
}
