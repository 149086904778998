.Dialog {
  .ant-modal-content {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.16);
  }

  .ant-modal-close {
    color: #db5151;
    //display: inline-block !important;
    //> * {
    //  width: 36px;
    //  height: 36px;
    //  line-height: 30px;
    //}
  }
  //
  //.ant-modal-header {
  //  border: none;
  //  padding-top: 40px;
  //
  //  .ant-modal-title {
  //    font-size: 26px;
  //    text-align: center;
  //  }
  //}
  //
  //.ant-modal-footer{
  //  border: none;
  //  padding: 50px;
  //
  //  .ant-btn{
  //    height: 60px;
  //    min-width: 245px;
  //    font-size: 20px;
  //    text-transform: uppercase;
  //    border-radius: 50rem;
  //    box-shadow: 0 5px 8px $info;
  //  }
  //
  //  .btn-danger{
  //    color: white;
  //    background-color: #DB5151;
  //  }
  //
  //  button + button{
  //    margin-left: 15px;
  //  }
  //}
}
