#subscriber {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#publisher {
  position: absolute;
  width: 260px;
  height: 160px;
  top: 40px;
  right: 10px;
  border: 3px solid white;
  border-radius: 3px;
  z-index: 2;
}

.video_actions {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}
.video_actions svg {
  font-size: 25px;
  color: #fff;
}
.video_actions .utility-icon {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  width: 60px;
  height: 60px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
#video_actions_container {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
}
#subscriber-utility h1 {
  color: #fff;
}
#subscriber-utility {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 40px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  padding-top: 10px;
}

.video_actions .utility-icon:nth-child(3) {
  background: red;
}
