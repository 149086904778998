@import "../../../styles/variables.scss";

$breakpoint-xs: 450px;
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

@mixin respond($breakpoint) {
  @if ($breakpoint==xs) {
    @media (min-width: 200px) and (max-width: $breakpoint-xs) {
      @content;
    }
  }

  @if ($breakpoint==phone) {
    @media (min-width: 451px) and (max-width: $breakpoint-sm) {
      @content;
    }
  }

  @if ($breakpoint==small) {
    @media (min-width: 641px) and (max-width: $breakpoint-md) {
      @content;
    }
  }

  @if ($breakpoint==medium) {
    @media (min-width: 769px) and (max-width: $breakpoint-lg) {
      @content;
    }
  }

  @if ($breakpoint==large) {
    @media (min-width: 1025px) and (max-width: $breakpoint-xl) {
      @content;
    }
  }

  @if ($breakpoint==full) {
    @media (min-width: 1281px) {
      @content;
    }
  }
}

.HeadroomMD {
  z-index: 500;
  .ant-layout-header {
    height: 64px !important;
  }

  .ant-menu {
    height: 100% !important;
  }
}

.headerEasyMD {
  .ant-menu-submenu-title {
    margin-left: 0.75rem !important;
    // display: flex;
    height: 100% !important;

    // align-items: center;
    // background-color: $primary-lighter !important;
    span {
      font-weight: 700 !important;
      color: white !important;
      font-size: 30px !important;
    }

    // &:hover {
    //    // background-color: $success !important;
    // }
  }
}
